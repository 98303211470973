import { useEffect, useState } from 'react';
import { Form, Button } from 'react-bootstrap';
import styles from './../../pages/CreateCountryPage.module.css';
import { ToastContainer, toast } from 'react-toastify';
import useAxiosGet from './../../hooks/useAxiosGet';
import useAxiosPost from './../../hooks/useAxiosPost';
import { useNavigate } from 'react-router-dom';

export default function CreateCampaignComponent() {
  const [agent, setAgent] = useState('');
  const [shortUrl, setShortUrl] = useState('');
  const [campaignText, setCampaignText] = useState('');
  const [agentsList, setAgentsList] = useState([]);
  const [shortUrlsList, setShortUrlsList] = useState([]);
  const [fetchAgents, agents] = useAxiosGet();
  const [postData, data, error, isLoading, message, errorCount] = useAxiosPost('campaigns/createNewCampaign');
  const navigate = useNavigate();

  const [fetchUrls, shortUrls] = useAxiosGet();
  useEffect(() => {
    if (error !== null && errorCount !== 0) {
      notifyError(error);
    }
  }, [error, errorCount]);
  useEffect(() => {
    if (message !== '') {
      notifySuccess(message);
      setTimeout(() => {
        navigate('/all-campaigns');
      }, 5000);
    }
  }, [message]);
  useEffect(() => {
    fetchAgents(`users/fetchAgentsAndSupervisors`);
  }, []);

  useEffect(() => {
    if (agents !== null) {
      setAgentsList(agents);
    }
  }, [agents]);

  useEffect(() => {
    if (shortUrls !== null) {
      setShortUrlsList(shortUrls);
    }
  }, [shortUrls]);
  useEffect(() => {
    console.log({ agent });
    if (agent !== '') {
      fetchUrls(`urls/shortUrls/getFreeShortUrlsOfAnAgent?userId=${agent}`);
    }
  }, [agent]);

  function submitForm(e) {
    e.preventDefault();
    //agent, shortUrl, campaignText
    if (agent === '') {
      notifyError(`Agent Is Required`);
      return;
    }
    if (shortUrl === '') {
      notifyError(`Please Choose A Short URL`);
      return;
    }
    if (campaignText === '') {
      notifyError(`Campaign Text Should Not Be Empty`);
      return;
    }
    const dataToSend = {
      intPkShortUrlID: Number(shortUrl),
      campaign_text: campaignText,
      intPkUserID: Number(agent),
    };
    try {
      postData(dataToSend);
    } catch (error) {
      notifyError(error?.response?.data?.error || `Server Error`);
    }
  }
  function notifyError(message) {
    toast.error(message, {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'light',
    });
  }

  function notifySuccess(message) {
    toast.success(message, {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'light',
    });
  }
  return (
    <>
      <ToastContainer />
      <div className={styles.form}>
        <h1 className={styles.heading}>Create New Campaign</h1>
        <Form>
          <Form.Group className="mb-3" controlId="">
            <Form.Label>Select Agent</Form.Label>
            <Form.Select
              onChange={(e) => {
                setAgent(e.target.value);
              }}
              value={agent}
            >
              <option value="">Choose An Agent</option>
              {agentsList.map((a) => {
                return (
                  <option value={a.intPkUserID} key={a.intPkUserID}>
                    {a.userFullname}
                  </option>
                );
              })}
            </Form.Select>
          </Form.Group>

          {agent !== '' ? (
            <Form.Group className="mb-3" controlId="">
              <Form.Label>Select Short Url</Form.Label>
              <Form.Select
                onChange={(e) => {
                  setShortUrl(e.target.value);
                }}
                value={shortUrl}
              >
                <option value="">Choose A Short URL</option>
                {shortUrlsList.map((url) => {
                  return (
                    <option value={url.intPkShortUrlID} key={url.intPkShortUrlID}>
                      {url.shortUrl}
                    </option>
                  );
                })}
              </Form.Select>
            </Form.Group>
          ) : null}

          <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
            <Form.Label>Campaign Text</Form.Label>
            <Form.Control as="textarea" rows={3} placeholder="Campaign Text..." value={campaignText} onChange={(e) => setCampaignText(e.target.value)} />
          </Form.Group>

          {/* {renderInput(`shortUrl`, 'Enter The Short URL', `Enter The Short URL`)} */}
          <div className={styles.btn}>
            <Button variant="primary" type="submit" onClick={submitForm}>
              Submit
            </Button>
          </div>
        </Form>
      </div>
    </>
  );
}

// Form field
// after choose agent call an api that will give short urls, choose one url
// text-area
