import { ToastContainer, toast } from 'react-toastify';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useEffect, useState } from 'react';
import useAxiosGet from './../../hooks/useAxiosGet';
import DataTable, { createTheme } from 'react-data-table-component';

import { Col, Row } from 'react-bootstrap';
import { formatDate } from '../../helpers/date';
import CardComponent from '../cards/Card';

export default function MyCampaignsComponent() {
  const [allCampaigns, setAllCampaigns] = useState([]);
  const [fetchCampaigns, campaigns] = useAxiosGet();
  useEffect(() => {
    fetchCampaigns(`campaigns/agents/getMyCampaigns`);
  }, []);
  useEffect(() => {
    if (campaigns !== null) {
      setAllCampaigns(campaigns);
    }
  }, [campaigns]);

  return (
    <>
      <ToastContainer />
      <h1 className="text-center">All Campaigns</h1>
      <Row>
        {allCampaigns.map((item) => {
          return <CardComponent key={item.intPkCampaignID} data={item} />;
        })}
      </Row>
    </>
  );
}
