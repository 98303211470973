import { useState } from 'react';
import axios from 'axios';
// const baseURL='http://localhost:5011/api/v1'
const baseURL = process.env.REACT_APP_API_URL;
const token = localStorage.getItem('auth_token');
const useAxiosGet = () => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const fetchData = async (url) => {
    setLoading(true);
    try {
      const response = await axios.get(`${baseURL}/${url}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setData(response.data.data);
      setLoading(false);
    } catch (error) {
      setError(error);
      setLoading(false);
    }
  };

  return [fetchData, data, loading, error];
};

export default useAxiosGet;
