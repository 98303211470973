import { useState } from 'react';
import axios from 'axios';
// const baseURL='http://localhost:5011/api/v1'
const baseURL = process.env.REACT_APP_API_URL;
const token = localStorage.getItem('auth_token');

const useAxiosPost = (url) => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [errorCount, setErrorCount] = useState(0);

  const postData = async (body) => {
    setIsLoading(true);
    try {
      const response = await axios.post(`${baseURL}/${url}`, body, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setData(response.data);
      setMessage(response.data.message);
      setError(null);
    } catch (error) {
      console.log({ error });
      setError(error.response.data.error);
      setErrorCount(errorCount + 1);
      setData(null);
    } finally {
      setTimeout(() => {
        setIsLoading(false);
      }, 5000);
    }
  };

  return [postData, data, error, isLoading, message, errorCount];
};

export default useAxiosPost;
