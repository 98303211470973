import { ToastContainer, toast } from 'react-toastify';

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import styles from './../../pages/CreateCountryPage.module.css';
import { useEffect, useState } from 'react';
import useAxiosPost from './../../hooks/useAxiosPost';
import { useNavigate } from 'react-router-dom';

export default function CreateVendorComponent() {
  const [newVendorState, setNewVendorState] = useState({
    vendorName: '',
  });
  const [postData, data, error, isLoading, message, errorCount] = useAxiosPost('urls/vendors/CreateVendor');
  const navigate = useNavigate();

  useEffect(() => {
    if (error !== null && errorCount !== 0) {
      notifyError(error);
    }
  }, [error, errorCount]);
  useEffect(() => {
    if (message !== '') {
      notifySuccess(message);
      setTimeout(() => {
        navigate('/all-vendors');
      }, 5000);
    }
  }, [message]);

  function submitForm(e) {
    e.preventDefault();
    const { vendorName } = newVendorState;
    if (vendorName === '') {
      notifyError(`Vendor Name is Required`);
      return;
    }

    const dataToSend = {
      vendorName,
    };
    try {
      postData(dataToSend);
    } catch (error) {
      notifyError(error?.response?.data?.error || `Server Error`);
    }
  }

  function notifyError(message) {
    toast.error(message, {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'light',
    });
  }

  function notifySuccess(message) {
    toast.success(message, {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'light',
    });
  }
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewVendorState({
      ...newVendorState,
      [name]: value,
    });
  };

  const renderInput = (name, placeholder, label) => {
    return (
      <Form.Group>
        <Form.Label> {label}</Form.Label>
        <Form.Control placeholder={`${placeholder}`} type="text" value={newVendorState[name]} name={`${name}`} onChange={handleInputChange} />
      </Form.Group>
    );
  };

  return (
    <>
      <ToastContainer />
      <div className={styles.form}>
        <h1 className={styles.heading}>Create New Short URL Vendor</h1>
        <Form>
          {renderInput(`vendorName`, 'Vendor Name', `Vendor Name`)}
          <div className={styles.btn}>
            <Button variant="primary" type="submit" disabled={isLoading} onClick={submitForm}>
              Submit
            </Button>
          </div>
        </Form>
      </div>
    </>
  );
}
