// import Nav from 'react-bootstrap/Nav';
// import { Link } from 'react-router-dom';

import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Offcanvas from 'react-bootstrap/Offcanvas';
import styles from './../../pages/HomePage.module.css';
import { useNavigate } from 'react-router-dom';
import Logo from './../../logos/logo-new.png';
export default function NavbarComponent() {
  const navigate = useNavigate();
  const role = localStorage.getItem('userRole');
  function handleRedirect(urlPath) {
    navigate(urlPath);
  }
  const getMenu = () => {
    if (role === 'admin') {
      return (
        <>
          {/* <NavDropdown.Item onClick={() => handleRedirect('/create-country')}>Create Country</NavDropdown.Item> */}
          {/* <NavDropdown.Item onClick={() => handleRedirect('/all-countries')}>All Countries</NavDropdown.Item> */}
          {/* <NavDropdown.Item onClick={() => handleRedirect('/create-operator')}>Create Operator</NavDropdown.Item> */}
          {/* <NavDropdown.Item onClick={() => handleRedirect('/all-operators')}>All Operators</NavDropdown.Item> */}
          {/* <NavDropdown.Item onClick={() => handleRedirect('/create-new-numbers-series')}>Create New Numbers Request</NavDropdown.Item> */}
          {/* <NavDropdown.Item onClick={() => handleRedirect('/all-numbers-requests')}>All Requests</NavDropdown.Item> */}
          <NavDropdown.Item onClick={() => handleRedirect('/create-vendor')}>Create ShortUrl Vendor</NavDropdown.Item>
          <NavDropdown.Item onClick={() => handleRedirect('/all-vendors')}>All Short Url Vendors</NavDropdown.Item>
          <NavDropdown.Item onClick={() => handleRedirect('/create-new-landing-page')}>Create New Landing Page</NavDropdown.Item>
          <NavDropdown.Item onClick={() => handleRedirect('/all-landing-pages')}>All Landing Pages</NavDropdown.Item>
          <NavDropdown.Item onClick={() => handleRedirect('/create-short-url')}>Create Short URL</NavDropdown.Item>
          <NavDropdown.Item onClick={() => handleRedirect('/all-short-urls')}>All Short URLs</NavDropdown.Item>
          {/* <NavDropdown.Item onClick={() => handleRedirect('/create-batch')}>Create Batch</NavDropdown.Item> */}
          {/* <NavDropdown.Item onClick={() => handleRedirect('/all-batches')}>All Batches</NavDropdown.Item> */}
          <NavDropdown.Item onClick={() => handleRedirect('/create-campaign')}>Create Campaigns</NavDropdown.Item>
          <NavDropdown.Item onClick={() => handleRedirect('/all-campaigns')}>All Campaigns</NavDropdown.Item>
          <NavDropdown.Item onClick={() => handleRedirect('/create-user-profile')}>Create User Profile</NavDropdown.Item>
          <NavDropdown.Item onClick={() => handleRedirect('/all-users')}>All Users</NavDropdown.Item>
        </>
      );
    }
    return (
      <>
        <NavDropdown.Item onClick={() => handleRedirect('/agents/my-campaigns')}>My Campaigns</NavDropdown.Item>
      </>
    );
  };
  return (
    <>
      {['md'].map((expand) => (
        <Navbar key={expand} bg="light" expand={expand} className="mb-3">
          <Navbar.Brand className={styles.logo}>
            <img src={Logo} style={{ width: '100px' }} />
          </Navbar.Brand>
          <Container fluid>
            <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} />
            <Navbar.Offcanvas id={`offcanvasNavbar-expand-${expand}`} aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`} placement="end">
              <Offcanvas.Header closeButton>
                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                  <img style={{ width: '100px' }} src={Logo} />
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <Nav className="justify-content-center flex-grow-1 pe-3">
                  <NavDropdown title="User" id={`offcanvasNavbarDropdown-expand-${expand}`}>
                    {getMenu()}
                    <NavDropdown.Item
                      href="#action4"
                      onClick={(e) => {
                        e.preventDefault();
                        localStorage.removeItem('auth_token');
                        localStorage.removeItem('userType');
                        window.location.href = '/login';
                      }}
                    >
                      Logout
                    </NavDropdown.Item>
                  </NavDropdown>
                </Nav>
              </Offcanvas.Body>
            </Navbar.Offcanvas>
          </Container>
        </Navbar>
      ))}
    </>
  );
}

// all countries list
// create new country => fields => country name and country code
