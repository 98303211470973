import { ToastContainer, toast } from 'react-toastify';

import { useEffect, useState } from 'react';
import useAxiosGet from './../../hooks/useAxiosGet';
import DataTable, { createTheme } from 'react-data-table-component';

import { Col, Row } from 'react-bootstrap';
import { formatDate } from '../../helpers/date';
import { campaignsEnums } from '../../enums';

export default function AllCampaignsComponent() {
  const [allCampaigns, setAllCampaigns] = useState([]);
  const [fetchCampaigns, campaigns] = useAxiosGet();
  useEffect(() => {
    fetchCampaigns(`campaigns/getAllCampaigns`);
  }, []);
  console.log(campaigns);

  useEffect(() => {
    if (campaigns !== null) {
      setAllCampaigns(campaigns);
    }
  }, [campaigns]);
  const getStatus = (value) => {
    let status = '';
    switch (value) {
      case campaignsEnums.PENDING:
        status = 'PENDING';
        break;
      case campaignsEnums.PROCESSING:
        status = 'PROCESSING';
        break;
      case campaignsEnums.COMPLETED:
        status = 'COMPLETED';
        break;
    }
    return status;
  };
  const columns = [
    {
      name: 'Agent Name',
      selector: (row) => row.userFullname,

      sortable: true,
      width: '150px',
    },
    {
      name: 'Short URL',
      selector: (row) => row.shortUrl,
      sortable: true,
      width: '200px',
    },
    {
      name: 'Campaign Created',
      selector: (row) => formatDate(row.dateCreated),
      sortable: true,
      width: '200px',
    },
    {
      name: 'Status',
      selector: (row) => getStatus(row.status),

      sortable: true,
      width: '150px',
    },
    {
      name: 'Campaign Text',
      selector: (row) => row.campaign_text,
      sortable: true,
      width: '200px',
    },
  ];
  createTheme(
    'solarized',
    {
      text: {
        primary: '#fff',
        secondary: '#fff',
      },
      background: {
        default: '#061b28',
      },
      context: {
        background: '##061b28',
        text: '#FFFFFF',
      },
      divider: {
        default: '#073642',
      },
      action: {
        button: 'rgba(0,0,0,.54)',
        hover: 'rgba(0,0,0,.08)',
        disabled: 'rgba(0,0,0,.12)',
      },
    },
    'dark',
  );
  const customStyles = {
    headCells: {
      style: {
        fontWeight: 'bold',
      },
    },
  };
  return (
    <>
      <ToastContainer />
      <h1 className="text-center">All Campaigns</h1>
      <Row>
        <Col md={2} lg={2} xl={2} xxl={2} sm={2}></Col>
        <Col md={8} lg={8} xl={8} xxl={8} sm={8}>
          <DataTable columns={columns} data={allCampaigns} customStyles={customStyles} theme="solarized" />
        </Col>
      </Row>
    </>
  );
}
