import { ToastContainer, toast } from 'react-toastify';

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import styles from './../../pages/CreateCountryPage.module.css';
import { useEffect, useState } from 'react';
import useAxiosPost from './../../hooks/useAxiosPost';
import { useNavigate } from 'react-router-dom';

export default function CreateNewProfileComponent() {
  const [newProfileState, setNewProfileState] = useState({
    userRole: '',
    userFullname: '',
    userName: '',
    password: '',
  });

  const [postData, data, error, isLoading, message, errorCount] = useAxiosPost('auth/users/createNewProfile');
  const navigate = useNavigate();
  useEffect(() => {
    if (error !== null && errorCount !== 0) {
      notifyError(error);
    }
  }, [error, errorCount]);
  useEffect(() => {
    if (message !== '') {
      notifySuccess(message);
      setTimeout(() => {
        navigate('/all-users');
      }, 5000);
    }
  }, [message]);

  function submitForm(e) {
    e.preventDefault();
    const { userRole, userName, userFullname, password } = newProfileState;
    if (userRole == '') {
      notifyError(`Please Choose A Role`);
      return;
    }
    if (userName === '') {
      notifyError(`No UserName Provided`);
      return;
    }
    if (userFullname === '') {
      notifyError(`Please Enter User Full Name`);
      return;
    }
    if (password == '') {
      notifyError(`Please Enter User Password`);
      return;
    }
    const dataToSend = {
      password,
      userFullname,
      userName,
      userRole,
    };
    try {
      postData(dataToSend);
    } catch (error) {
      notifyError(error?.response?.data?.error || `Server Error`);
    }
  }

  function notifyError(message) {
    toast.error(message, {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'light',
    });
  }

  function notifySuccess(message) {
    toast.success(message, {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'light',
    });
  }
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewProfileState({
      ...newProfileState,
      [name]: value,
    });
  };

  const renderInput = (name, placeholder, label) => {
    return (
      <Form.Group>
        <Form.Label> {label}</Form.Label>
        <Form.Control placeholder={`${placeholder}`} type="text" value={newProfileState[name]} name={`${name}`} onChange={handleInputChange} />
      </Form.Group>
    );
  };

  return (
    <>
      <ToastContainer />
      <div className={styles.form}>
        <h1 className={styles.heading}>Create New User Profile</h1>
        <Form>
          <Form.Group className="mb-3" controlId="">
            <Form.Label>Choose Role</Form.Label>
            <Form.Select
              onChange={(e) => {
                setNewProfileState({
                  ...newProfileState,
                  userRole: e.target.value,
                });
              }}
              value={newProfileState.userRole}
            >
              <option value="">Choose A Role</option>
              <option value="agent">Agent</option>
              <option value="supervisor">Supervisor</option>
            </Form.Select>
          </Form.Group>
          {renderInput(`userName`, 'User Name', `User Name`)}
          {renderInput(`userFullname`, 'User Full Name', `User Full Name`)}
          {renderInput(`password`, 'Enter Password', `Enter Password`)}
          <div className={styles.btn}>
            <Button variant="primary" type="submit" disabled={isLoading} onClick={submitForm}>
              Submit
            </Button>
          </div>
        </Form>
      </div>
    </>
  );
}
