import Card from 'react-bootstrap/Card';
import styles from './Card.module.css';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import { Row, Col, Container, Button } from 'react-bootstrap';
export default function CardComponent({ data }) {
  const copyToClipboard = async (textToCopy) => {
    try {
      await navigator.clipboard.writeText(textToCopy);
      console.log('Text copied to clipboard');
    } catch (error) {
      console.error('Error copying text: ', error);
    }
  };
  return (
    <Container>
      <Row>
        <Col lg={2} md={2} xl={2} xxl={2} sm={2}></Col>
        <Col lg={8} md={8} xl={8} xxl={8} sm={8}>
          <Card bg={`secondary`} key={`secondary`} text={`white`} className="mb-2">
            <Card.Header>Campaign</Card.Header>
            <Card.Body>
              {/* <Card.Title>{variant} Card Title </Card.Title> */}
              <Card.Text style={{ whiteSpace: 'pre-line' }}>
                {data.campaign_text}
                <br />
                {data.shortUrl}
              </Card.Text>
              <div className={styles.btn}>
                <CopyToClipboard
                  text={data.campaign_text + '\n' + data.shortUrl}
                  onCopy={() => {
                    console.log('copy to clipboard');
                  }}
                >
                  <button className="btn btn-primary"> Copy</button>
                </CopyToClipboard>
                {/* <Button
                  variant="primary"
                  type="button"
                  onClick={(e) => {
                    copyToClipboard(data.campaign_text + '\n' + data.shortUrl);
                  }}
                >
                  Copy
                </Button> */}
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}
