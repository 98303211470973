import { Route, Routes, BrowserRouter as Router, useNavigate } from 'react-router-dom';
import LoginFormPage from './pages/LoginFormPage';
import HomePage from './pages/HomePage';
import CreateCountryPage from './pages/CreateCountryPage';
import Navbar from './components/nav/NavbarComponent';
import AllCountriesPage from './pages/AllCountries';
import CreateOperatorPage from './pages/CreateOperator';
import AllOperatorsPage from './pages/AllOperators';
import CreateNewNumberSeriesRequestPage from './pages/NewNumbersRequestPage';
import AllNumbersRequestsPage from './pages/AllNumberSeriesRequestsPage';
import CreateVendorPage from './pages/CreateVendorPage';
import AllVendorsPage from './pages/AllvendorsPage';
import CreateNewLandingPage from './pages/CreateNewLandingPage';
import AllLandingPagesPage from './pages/AllLandingPages';
import NewShortURLPage from './pages/CreateNewShortURLPage';
import AllShortUrlsPage from './pages/AllShortURls';
import CreateNewBatchPage from './pages/CreateNewBatch';
import AllBatchesPage from './pages/AllBatches';
import CreateCampaignsPage from './pages/CreateCampaigns';
import AllCampaignsPage from './pages/AllCampaignsPage';
import CardComponent from './components/cards/Card';
import CreateNewProfilePage from './pages/CreateNewProfile';
import AllUsersProfilePage from './pages/AllUserProfiles';
import AllCampaignsByAgentPage from './pages/AllAgentCampaigns';

export default function App() {
  const isLoggedIn = localStorage.getItem('auth_token') || false;
  const role = localStorage.getItem('userRole');

  return (
    <>
      <Router>
        {isLoggedIn ? <Navbar /> : null}
        <Routes>
          <Route path="/login" element={<LoginFormPage />} />
          <Route
            path="/"
            element={
              <ProtectedRoute
                allowedRoles={['admin', 'agent', 'supervisor']}
                Component={role === 'admin' ? AllUsersProfilePage : AllCampaignsByAgentPage}
                isLoggedIn={isLoggedIn}
              />
            }
          />
          {/* <Route path="/all-countries" element={<ProtectedRoute allowedRoles={['admin']} Component={AllCountriesPage} isLoggedIn={isLoggedIn} />} /> */}
          {/* <Route path="/create-country" element={<ProtectedRoute allowedRoles={['admin']} Component={CreateCountryPage} isLoggedIn={isLoggedIn} />} /> */}
          {/* <Route path="/create-operator" element={<ProtectedRoute allowedRoles={['admin']} Component={CreateOperatorPage} isLoggedIn={isLoggedIn} />} /> */}
          {/* <Route path="/all-operators" element={<ProtectedRoute allowedRoles={['admin']} Component={AllOperatorsPage} isLoggedIn={isLoggedIn} />} /> */}
          {/* <Route
            path="/create-new-numbers-series"
            element={<ProtectedRoute allowedRoles={['admin']} Component={CreateNewNumberSeriesRequestPage} isLoggedIn={isLoggedIn} />}
          /> */}
          {/* <Route path="/all-numbers-requests" element={<ProtectedRoute allowedRoles={['admin']} Component={AllNumbersRequestsPage} isLoggedIn={isLoggedIn} />} /> */}
          <Route path="/create-vendor" element={<ProtectedRoute allowedRoles={['admin']} Component={CreateVendorPage} isLoggedIn={isLoggedIn} />} />
          <Route path="/all-vendors" element={<ProtectedRoute allowedRoles={['admin']} Component={AllVendorsPage} isLoggedIn={isLoggedIn} />} />
          <Route path="/create-new-landing-page" element={<ProtectedRoute allowedRoles={['admin']} Component={CreateNewLandingPage} isLoggedIn={isLoggedIn} />} />
          <Route path="/all-landing-pages" element={<ProtectedRoute allowedRoles={['admin']} Component={AllLandingPagesPage} isLoggedIn={isLoggedIn} />} />
          <Route path="/create-short-url" element={<ProtectedRoute allowedRoles={['admin']} Component={NewShortURLPage} isLoggedIn={isLoggedIn} />} />
          <Route path="/all-short-urls" element={<ProtectedRoute allowedRoles={['admin']} Component={AllShortUrlsPage} isLoggedIn={isLoggedIn} />} />
          {/* <Route path="/create-batch" element={<ProtectedRoute allowedRoles={['admin']} Component={CreateNewBatchPage} isLoggedIn={isLoggedIn} />} /> */}
          {/* <Route path="/all-batches" element={<ProtectedRoute allowedRoles={['admin']} Component={AllBatchesPage} isLoggedIn={isLoggedIn} />} /> */}
          <Route path="/create-campaign" element={<ProtectedRoute allowedRoles={['admin']} Component={CreateCampaignsPage} isLoggedIn={isLoggedIn} />} />
          <Route path="/all-campaigns" element={<ProtectedRoute allowedRoles={['admin']} Component={AllCampaignsPage} isLoggedIn={isLoggedIn} />} />
          <Route path="/create-user-profile" element={<ProtectedRoute allowedRoles={['admin']} Component={CreateNewProfilePage} isLoggedIn={isLoggedIn} />} />
          <Route path="/all-users" element={<ProtectedRoute allowedRoles={['admin']} Component={AllUsersProfilePage} isLoggedIn={isLoggedIn} />} />
          {/* Agent Routes */}
          <Route path="/agents/my-campaigns" element={<ProtectedRoute allowedRoles={['agent', 'supervisor']} Component={AllCampaignsByAgentPage} isLoggedIn={isLoggedIn} />} />
        </Routes>
      </Router>
    </>
  );
}

function ProtectedRoute(props) {
  const { Component, isLoggedIn, allowedRoles } = props;
  const role = localStorage.getItem('userRole');
  const navigate = useNavigate();
  if (!isLoggedIn) {
    // navigate('/login');
    return (window.location.href = '/login');
  }
  if (!allowedRoles?.includes(role)) {
    window.location.href = '/';
  }
  return <Component />;
}
