import { ToastContainer, toast } from 'react-toastify';

import { useEffect, useState } from 'react';
import useAxiosGet from './../../hooks/useAxiosGet';
import useAxiosPost from './../../hooks/useAxiosPost';

import DataTable, { createTheme } from 'react-data-table-component';

import { Col, Row, Button } from 'react-bootstrap';
import { formatDate } from '../../helpers/date';
import AssignShortUrlToUsersModal from '../models/AssignShortUrlToAgent';

export default function AllNumbersRequestsComponent() {
  const [allShortUrls, setAllShortUrls] = useState([]);
  const [allUsers, setAllUsers] = useState([]);
  const [activeShortUrl, setActiveShortUrl] = useState(-1);
  const [isDisabled, setIsDisabled] = useState(false);
  const [actualUsers, setActualUsers] = useState([]);
  const [show, setShow] = useState(false);
  const [fetchShortUrls, shortUrls] = useAxiosGet();
  const [search, setSearch] = useState('');
  const [fetchUsers, users] = useAxiosGet();
  const [postData, data, error, isLoading, message, errorCount] = useAxiosPost('urls/shortUrls/assignURLToUser');
  useEffect(() => {
    if (error !== null && errorCount !== 0) {
      notifyError(error);
    }
  }, [error, errorCount]);
  useEffect(() => {
    if (message !== '') {
      notifySuccess(message);
      setTimeout(() => {
        window.location.reload();
      }, 5000);
    }
  }, [message]);
  useEffect(() => {
    fetchShortUrls(`urls/shortUrls/getAllShortURLs`);
    fetchUsers(`users/fetchAgentsAndSupervisors`);
  }, []);
  useEffect(() => {
    if (shortUrls !== null) {
      setAllShortUrls(shortUrls);
    }
    if (users !== null) {
      setAllUsers(users);
      setActualUsers(users);
    }
  }, [shortUrls, users]);
  const handleClose = () => {
    setActiveShortUrl(-1);
    setShow(false);
  };
  const handleSearch = (value) => {
    console.log({ value });
    setSearch(value);
    const searchedUsers = actualUsers.filter((item) => {
      if (item.userFullname.toLowerCase().indexOf(value) > -1 || item.userName.toLowerCase().indexOf(value) > -1) {
        return item;
      }
    });
    setAllUsers(searchedUsers);
    // console.log({ searchedUsers });
  };
  const handleUserAssignment = (assignedUserId) => {
    setShow(false);
    setIsDisabled(true);
    const dataToSend = {
      userId: assignedUserId,
      urlId: activeShortUrl,
    };
    console.log({ dataToSend });
    postData(dataToSend);
  };
  const columns = [
    {
      name: 'Short Url',
      selector: (row) => row.shortUrl,

      sortable: true,
      width: '150px',
    },
    {
      name: 'Vendor Name',
      selector: (row) => row.vendorName,

      sortable: true,
      width: '200px',
    },
    {
      name: 'Landing Page Name',
      selector: (row) => row.pageName,

      sortable: true,
      width: '200px',
    },
    {
      name: 'Assigned To',
      selector: (row) => (row.intPkUserID === null ? 'No Agent Assigned' : row.userFullname),

      sortable: true,
      width: '150px',
    },

    {
      name: 'Created At',
      selector: (row) => formatDate(row.dateCreated),
      sortable: true,
      width: '200px',
    },
    {
      name: 'Assign User',
      cell: (row) => (
        <Button
          variant="primary"
          disabled={row.intPkUserID !== null ? true : false || isDisabled}
          onClick={(e) => {
            console.log({ row });
            e.preventDefault();
            setActiveShortUrl(row.intPkShortUrlID);
            setShow(true);
          }}
        >
          Assign To Agent
        </Button>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
      width: '300px',
    },
  ];
  createTheme(
    'solarized',
    {
      text: {
        primary: '#fff',
        secondary: '#fff',
      },
      background: {
        default: '#061b28',
      },
      context: {
        background: '##061b28',
        text: '#FFFFFF',
      },
      divider: {
        default: '#073642',
      },
      action: {
        button: 'rgba(0,0,0,.54)',
        hover: 'rgba(0,0,0,.08)',
        disabled: 'rgba(0,0,0,.12)',
      },
    },
    'dark',
  );
  const customStyles = {
    headCells: {
      style: {
        fontWeight: 'bold',
      },
    },
  };
  function notifyError(message) {
    toast.error(message, {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'light',
    });
  }

  function notifySuccess(message) {
    toast.success(message, {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'light',
    });
  }
  return (
    <>
      <ToastContainer />
      <h1 className="text-center">All Short URLs List</h1>
      {show === true ? (
        <AssignShortUrlToUsersModal
          handleUserAssignment={handleUserAssignment}
          search={search}
          handleSearch={handleSearch}
          users={allUsers}
          handleClose={handleClose}
          show={show}
        />
      ) : null}
      <Row>
        <Col md={1} lg={1} xl={1} xxl={1} sm={1}></Col>
        <Col md={10} lg={10} xl={10} xxl={10} sm={10}>
          <DataTable columns={columns} data={allShortUrls} customStyles={customStyles} theme="solarized" />
        </Col>
      </Row>
    </>
  );
}
