import { ToastContainer, toast } from 'react-toastify';

import { Col, Row, Modal, Table, Button, Form } from 'react-bootstrap';
import { useEffect, useState } from 'react';

export default function AssignShortUrlToUsersModal({ handleClose, handleSearch, search, show, users, handleUserAssignment }) {
  const [allUsers, setAllUsers] = useState([]);
  useEffect(() => {
    setAllUsers(users);
  }, [users]);
  return (
    <>
      <Modal dialogClassName="my-create-child-form-modal" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Choose An Agent</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h1 className="text-center">All Agents</h1>
          <Row style={{ marginBottom: '10px', maxWidth: '300px' }}>
            <Form.Control type="text" placeholder="Search Agent" value={search} onChange={(e) => handleSearch(e.target.value)} />
          </Row>
          <Row style={{ textAlign: 'center' }}>
            <Table bordered striped>
              <thead>
                <tr>
                  <th>Full Name</th>
                  <th>UserName</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {allUsers.map((user) => {
                  return (
                    <tr key={user.intPkUserID}>
                      <td>{user.userFullname}</td>
                      <td>{user.userName}</td>
                      <td>
                        <Button
                          variant="primary"
                          onClick={(e) => {
                            handleUserAssignment(user.intPkUserID);
                          }}
                        >
                          Assign
                        </Button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
