import { ToastContainer, toast } from 'react-toastify';

import { useEffect, useState } from 'react';
import useAxiosGet from './../../hooks/useAxiosGet';
import DataTable, { createTheme } from 'react-data-table-component';
import { Col, Row } from 'react-bootstrap';
import { formatDate } from '../../helpers/date';

export default function AllVendorsComponent() {
  const [allVendors, setAllVendors] = useState([]);
  const [fetchVendors, vendors] = useAxiosGet();
  useEffect(() => {
    fetchVendors(`urls/vendors/getAllVendors`);
  }, []);
  useEffect(() => {
    if (vendors !== null) {
      setAllVendors(vendors);
    }
  }, [vendors]);

  const columns = [
    {
      name: 'Vendor Name',
      selector: (row) => row.vendorName,

      sortable: true,
      width: '150px',
    },
    {
      name: 'Created Date',
      selector: (row) => formatDate(row.dateCreated),
      sortable: true,
      width: '200px',
    },
  ];
  createTheme(
    'solarized',
    {
      text: {
        primary: '#fff',
        secondary: '#fff',
      },
      background: {
        default: '#061b28',
      },
      context: {
        background: '##061b28',
        text: '#FFFFFF',
      },
      divider: {
        default: '#073642',
      },
      action: {
        button: 'rgba(0,0,0,.54)',
        hover: 'rgba(0,0,0,.08)',
        disabled: 'rgba(0,0,0,.12)',
      },
    },
    'dark',
  );
  const customStyles = {
    headCells: {
      style: {
        fontWeight: 'bold',
      },
    },
  };
  return (
    <>
      <ToastContainer />
      <h1 className="text-center">All Short URLs Vendors</h1>
      <Row>
        <Col md={3} lg={3} xl={3} xxl={3} sm={3}></Col>
        <Col md={6} lg={6} xl={6} xxl={6} sm={6}>
          <DataTable columns={columns} data={allVendors} customStyles={customStyles} theme="solarized" />
        </Col>
      </Row>
    </>
  );
}
