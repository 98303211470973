import { ToastContainer, toast } from 'react-toastify';

import { useEffect, useState } from 'react';
import useAxiosGet from './../../hooks/useAxiosGet';
import DataTable, { createTheme } from 'react-data-table-component';

import { Col, Row } from 'react-bootstrap';
import { formatDate } from '../../helpers/date';

export default function AllUsersProfileComponent() {
  const [allUsers, setAllUsers] = useState([]);
  const [fetchUsers, users] = useAxiosGet();
  useEffect(() => {
    fetchUsers(`users/fetchAgentsAndSupervisors`);
  }, []);
  useEffect(() => {
    if (users !== null) {
      setAllUsers(users);
    }
  }, [users]);
  function capitalizeFirstLetter(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }
  const columns = [
    {
      name: 'Full Name',
      selector: (row) => row.userFullname,

      sortable: true,
      width: '150px',
    },

    {
      name: 'User Name',
      selector: (row) => row.userName,
      sortable: true,
      width: '200px',
    },
    {
      name: 'User Type',
      selector: (row) => capitalizeFirstLetter(row.userRole),
      sortable: true,
      width: '200px',
    },
    {
      name: 'User Created',
      selector: (row) => formatDate(row.dateCreated),
      sortable: true,
      width: '200px',
    },
  ];
  createTheme(
    'solarized',
    {
      text: {
        primary: '#fff',
        secondary: '#fff',
      },
      background: {
        default: '#061b28',
      },
      context: {
        background: '##061b28',
        text: '#FFFFFF',
      },
      divider: {
        default: '#073642',
      },
      action: {
        button: 'rgba(0,0,0,.54)',
        hover: 'rgba(0,0,0,.08)',
        disabled: 'rgba(0,0,0,.12)',
      },
    },
    'dark',
  );
  const customStyles = {
    headCells: {
      style: {
        fontWeight: 'bold',
      },
    },
  };
  return (
    <>
      <ToastContainer />
      <h1 className="text-center">All Users</h1>
      <Row>
        <Col md={2} lg={2} xl={2} xxl={2} sm={2}></Col>
        <Col md={8} lg={8} xl={8} xxl={8} sm={8}>
          <DataTable columns={columns} data={allUsers} customStyles={customStyles} theme="solarized" />
        </Col>
      </Row>
    </>
  );
}
