import { ToastContainer, toast } from 'react-toastify';

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import styles from './../../pages/CreateCountryPage.module.css';
import { useEffect, useState } from 'react';
import useAxiosPost from './../../hooks/useAxiosPost';
import useAxiosGet from './../../hooks/useAxiosGet';
import { useNavigate } from 'react-router-dom';

export default function CreateNewShortUrlComponent() {
  const [newShortURLstate, setNewShortUrlState] = useState({
    vendor: '',
    landingPageId: '',
    shortUrl: '',
  });
  const [allLandingPages, setAllLandingPages] = useState([]);
  const [allVendors, setAllVendors] = useState([]);
  const [fetchLandingPages, landingPages] = useAxiosGet();
  const [fetchVendors, vendors] = useAxiosGet();
  const [postData, data, error, isLoading, message, errorCount] = useAxiosPost('urls/shortUrls/createNewShortUrl');
  const navigate = useNavigate();
  useEffect(() => {
    fetchLandingPages(`urls/landingPages/getAllLandingPages`);
    fetchVendors(`urls/vendors/getAllVendors`);
  }, []);
  useEffect(() => {
    if (landingPages !== null) {
      setAllLandingPages(landingPages);
    }
    if (vendors !== null) {
      setAllVendors(vendors);
    }
  }, [landingPages, vendors]);
  useEffect(() => {
    if (error !== null && errorCount !== 0) {
      notifyError(error);
    }
  }, [error, errorCount]);
  useEffect(() => {
    if (message !== '') {
      notifySuccess(message);
      setTimeout(() => {
        navigate('/all-short-urls');
      }, 5000);
    }
  }, [message]);

  function submitForm(e) {
    e.preventDefault();
    const { vendor, landingPageId, shortUrl } = newShortURLstate;
    if (landingPageId === '') {
      notifyError(`Please Select A Landing Page`);
      return;
    }
    if (vendor === '') {
      notifyError(`Please Choose A Vendor`);
      return;
    }

    if (shortUrl === '') {
      notifyError(`Please Enter A Short URL`);
      return;
    }
    const dataToSend = {
      vendor,
      landingPageId,
      shortUrl,
    };
    try {
      postData(dataToSend);
    } catch (error) {
      notifyError(error?.response?.data?.error || `Server Error`);
    }
  }

  function notifyError(message) {
    toast.error(message, {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'light',
    });
  }

  function notifySuccess(message) {
    toast.success(message, {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'light',
    });
  }
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewShortUrlState({
      ...newShortURLstate,
      [name]: value,
    });
  };

  const renderInput = (name, placeholder, label) => {
    return (
      <Form.Group>
        <Form.Label> {label}</Form.Label>
        <Form.Control placeholder={`${placeholder}`} type="text" value={newShortURLstate[name]} name={`${name}`} onChange={handleInputChange} />
      </Form.Group>
    );
  };

  return (
    <>
      <ToastContainer />
      <div className={styles.form}>
        <h1 className={styles.heading}>Create New Short URL</h1>
        <Form>
          <Form.Group className="mb-3" controlId="">
            <Form.Label>Select Landing Page</Form.Label>
            <Form.Select
              onChange={(e) => {
                setNewShortUrlState({
                  ...newShortURLstate,
                  landingPageId: e.target.value,
                });
              }}
              value={newShortURLstate.landingPageId}
            >
              <option value="">Choose A Landing Page</option>
              {allLandingPages.map((page) => {
                return (
                  <option value={page.intPkLandingPageID} key={page.intPkLandingPageID}>
                    {page.pageName}
                  </option>
                );
              })}
            </Form.Select>
          </Form.Group>

          <Form.Group className="mb-3" controlId="">
            <Form.Label>Select Short URL Vendor</Form.Label>
            <Form.Select
              onChange={(e) => {
                setNewShortUrlState({
                  ...newShortURLstate,
                  vendor: e.target.value,
                });
              }}
              value={newShortURLstate.vendor}
            >
              <option value="">Choose A Short URL Vendor</option>
              {allVendors.map((vendor) => {
                return (
                  <option value={vendor.intPkVendorID} key={vendor.intPkVendorID}>
                    {vendor.vendorName}
                  </option>
                );
              })}
            </Form.Select>
          </Form.Group>

          {renderInput(`shortUrl`, 'Enter The Short URL', `Enter The Short URL`)}
          <div className={styles.btn}>
            <Button variant="primary" type="submit" disabled={isLoading} onClick={submitForm}>
              Submit
            </Button>
          </div>
        </Form>
      </div>
    </>
  );
}
