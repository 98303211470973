export const batchEnums = {
  PENDING: 0,
  SYNCING: 1,
  SYNCED: 2,
  COMPLETED: 3,
};

export const campaignsEnums = {
  PENDING: 0,
  PROCESSING: 1,
  COMPLETED: 2,
};
