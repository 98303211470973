import { ToastContainer, toast } from 'react-toastify';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useState, useEffect } from 'react';
import useAxiosPost from '../hooks/useAxiosPost';

export default function LoginFormPage() {
  const [userName, setUserName] = useState('');
  const [password, setPassword] = useState('');

  const [postLogin, loginResponse, loginError, isLoginLoading, loginResponseMessage, loginCounter] = useAxiosPost('auth/login');
  useEffect(() => {
    if (localStorage.getItem('auth_token') && localStorage.getItem('userRole')) {
      window.location.href = '/';
    }
  }, []);
  useEffect(() => {
    if (loginError !== null && loginCounter !== 0) {
      notifyError(loginError);
    }
  }, [loginError, loginCounter]);

  useEffect(() => {
    if (loginResponse !== null) {
      console.log({ loginResponse });
      const { token, userRole } = loginResponse;
      if (token) {
        console.log('token received');
        localStorage.setItem('auth_token', token);
        localStorage.setItem('userRole', userRole);
        window.location.href = '/';
      }
    }
  }, [loginResponse]);

  async function handleLogin(e) {
    e.preventDefault();

    const dataToSend = {
      userName,
      password,
    };
    if (userName === '') {
      notifyError('No User Name provided');
      return;
    }
    if (password === '') {
      notifyError('No password provided');
      return;
    }
    try {
      postLogin(dataToSend);
    } catch (error) {
      notifyError(error?.response?.data?.error || `Server Error`);
    }
  }

  function notifyError(message) {
    toast.error(message, {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'light',
    });
  }

  return (
    <>
      <ToastContainer />
      <Container className="mt-5">
        <Row className="justify-content-center">
          <Col xs lg="6" md="6" className="bg-dark text-white p-5">
            <h3 className="mb-5 text-center">Login Here</h3>
            <Form>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>User Name</Form.Label>
                <Form.Control type="text" value={userName} onChange={(e) => setUserName(e.target.value)} placeholder="userName" />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label>Password</Form.Label>
                <Form.Control value={password} onChange={(e) => setPassword(e.target.value)} type="password" placeholder="Password" />
              </Form.Group>
              <Button onClick={handleLogin} variant="primary" type="submit">
                Submit
              </Button>
            </Form>
          </Col>
        </Row>
      </Container>
    </>
  );
}
